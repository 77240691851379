import React from 'react';
import { connect } from 'react-redux';
import { Marker } from 'react-leaflet'
import DataplorMap from '../../shared/components/dataplor_map';

class ObservationsMapPanel extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {};
    this.map = React.createRef();
  }

  render()
  {
    const { observation } = this.props;

    const longitude = observation.longitude
    const latitude = observation.latitude
    //observation.getIn(["response", "data",])
    return (
      <DataplorMap center={[latitude, longitude]} zoom = {15} key = "place-map" style={{height: '75vh'}}>
        <Marker position={[latitude, longitude]} />
      </DataplorMap>
    )
  }
};


const mapStateToProps = (state,props) =>
{
  const observationId = props.match.params.id;
  const observation = state.getIn(['observations',observationId]);

  let lead;
  if(observation.lead_id)
  {
    lead = state.getIn(['leads',observation.lead_id.toString()]);
  }

  return {
    observation: observation,
    images: state.get('images').filter(i => i.observation_id === parseInt(observationId,10)),
    lead: lead
  }
};

export default connect(mapStateToProps)(ObservationsMapPanel);
