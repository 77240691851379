import React from 'react';
import { connect } from 'react-redux';
import PlacesPatchRow from './places_patch_row'
import {raw} from '../../shared/schemas/data_schema';


class PlacesPatchPanel extends React.Component
{

  render(){
    const { place } = this.props;

    const patch = place.patch
    const attrOrder = Object.keys(raw.properties)

    let rows
    if(patch)
    {
      rows = patch.sortBy((v,k) => ((attrOrder.indexOf(v.get("p").substring(1))+1) || 999 )).map(row => {
        return <PlacesPatchRow patch = {row}/>
      });
    }


    // const sortedData = place.data.toKeyedSeq().sortBy((v,k) => ((attrOrder.indexOf(k)+1) || 999 ))



    return (
      <div className="row">
        <div className="col-12">
          <div  className="card">
            <div className="card-header h5">
              Patch:
            </div>
            <div className="card-body">
              {rows}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state,props) =>
{
  return{
    place: state.getIn(['places',props.match.params.id])
  };
};

export default connect(mapStateToProps)(PlacesPatchPanel);
