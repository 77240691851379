import React from 'react';
import { connect } from 'react-redux';
import Loading from '../../shared/components/loading';
import DuplicateChecksIndex from './duplicate_checks_index';
import PlacesActions from '../actions/places_actions';
import MergeEventsActions from '../actions/merge_events_actions';

class PlacesDeduplicatorPanel extends React.Component
{

  constructor(props)
  {
    super(props);
    this.state = {};
  }


  componentDidMount()
  {
    if(this.props.duplicateCandidateId)
    {
      this.fetchData(this.props.duplicateCandidateId)
    }
  }

  render()
  {
    const { place,duplicateCandidate,duplicateCandidateId } = this.props;

    if(duplicateCandidate)
    {
      return (
        <div className="visits-data mb-2">
          <DuplicateChecksIndex original={place} duplicate={duplicateCandidate} onMerge={this.onMerge}/>
        </div>
      )
    }
    else
    {
      if(duplicateCandidateId)
      {
        return <Loading />
      }
      else
      {
        return (
          <div className="visits-data mb-2">
            <form onSubmit={this.onSubmit}  className="form-inline">
              <label>
                Id of place to compare:
              </label>
              <input className="ml-2 form-control" type="text" value={this.state.query||""} onChange={this.onChange} />

              <input type="submit" className="btn btn-primary ml-2" value="Compare" />
            </form>
          </div>
        )
      }
    }
  }

  fetchData = (id)=>
  {
    PlacesActions.show(id);
  }

  onSubmit = (event) =>
  {
    event.preventDefault();
    this.fetchData(this.state.query);
    this.props.history.push(`/places/${this.props.place.id}/compare/${this.state.query}`);
  }

  onChange = (event) =>
  {
    this.setState({query: event.target.value})
  }

  onMerge = (shouldMerge) =>
  {
    const { place, duplicateCandidate } = this.props;
    this.setState({query: ""})
    MergeEventsActions.create({data: {attributes: {original_id: place.id, duplicate_id: duplicateCandidate.id, should_merge: shouldMerge}}})
    this.props.history.push(`/places/${this.props.place.id}/compare`);
  }
}

const mapStateToProps = (state,props) =>
{
  const dcid =  props.match.params.candidate_id
  const children = state.get('places').filter(i => props.match.params.id === i.root_id && i.id !== props.match.params.id);

  return{
    place: state.getIn(['places', props.match.params.id]),
    duplicateCandidate: state.getIn(['places', dcid]),
    duplicateCandidateId:  dcid,
    children: children

  };
}

export default connect(mapStateToProps)(PlacesDeduplicatorPanel);
