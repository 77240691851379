import Immutable from 'immutable';

const defaults = {
  id: null,
  number: null,
  name: null,
  street_number: null,
  street_name: null,
  address: null,
  address2: null,
  neighborhood: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  created_at: null,
  updated_at: null,
  ignored_at: null,
  patched_at: null,
  longitude: null,
  latitude: null,
  location_accuracy: null,
  business_category_id: null,
  interior_photo: null,
  exterior_photo: null,
  data: Immutable.fromJS({}),
  confidence: Immutable.fromJS({}),
  external_ids: Immutable.fromJS({}),
  patch: Immutable.fromJS([]),
  parent_id: null,
  root_id: null,
  path: null,
  relationships: Immutable.fromJS({}),
};

class Place extends Immutable.Record(defaults){

  inlineLocalAddress(){
    const line1 = [this.street_number,this.street_name].filter(x => x).join(" ");
    return [line1,this.address2].filter(x => x).join(", ");
  }

  inlineAddress(){
    return `${this.inlineLocalAddress()}, ${this.city}, ${this.state} ${this.postal_code}`;
  }
}

export default Place;
