import React from 'react';
import cn from 'classnames';
import qs from 'qs';
import ReactPaginate from 'react-paginate';

import SearchBar from './search_bar';

import { cleanParams,parseQuery } from '../../shared/helpers/request_helpers';

class IndexView extends React.PureComponent {

  componentDidMount()
  {
    this.fetchData(this.parsedQuery());
  }

  parsedQuery = () => {    
    if(this.props.filter)
      return this.props.filter
    else
      return parseQuery(this.props.location.search);
  }

  generateHeaders = (columns) => {
    return columns.map(column => {
      if(column.col){
        /* eslint-disable jsx-a11y/anchor-is-valid */
        return (
          <th className={cn(column.className)} key={column.col}>
            <a href="" onClick={this.toggleSort} key={column.col} data-col={column.col}>
              {column.label}
            </a>
          </th>
        );
        /* eslint-enable jsx-a11y/anchor-is-valid */
      } else {
        return (
          <th key={column.label}><span>{column.label}</span></th>
        );
      }
    })
  }

  toggleSort = e => {
    e.preventDefault()
    const pq = this.parsedQuery();
    const col = e.target.getAttribute('data-col');
    const dir = (pq.sort === col && pq.sort_dir === 'asc') ? 'desc' : 'asc'
    this.mergeQuery({sort: col, sort_dir: dir, 'page_number': 1})
  }

  mergeQuery = (update) => {
    const newQuery = cleanParams(this.parsedQuery(),update);
    this.fetchData(newQuery)
    this.props.history.push(`${this.props.location.pathname}?${qs.stringify(newQuery)}`);
  }

  handleSearch = (value) => {
    // search disabled while we figure out the server
    // this.mergeQuery({search: value, page_number: 1});
  }

  fetchData = (query) => {
    console.log("Implement fetchData!",query)
  }

  onPageChange = (e) => {
    this.mergeQuery({ page_number: (e.selected + 1)});
  }

  searchBar = (props={}) => {
    return (
      <SearchBar onChange={this.handleSearch} query={this.parsedQuery()}/>
    );
  }

  pagination = (props={}) => {
    const {total,page_size,page_number,...rest} = props;
    const calculated = {}

    if(total && page_size){
      calculated.pageCount = (total && page_size) ? Math.ceil(total / page_size) : 0;
    } else {
      calculated.pageCount = 0
    }

    if(page_number){
      calculated.forcePage = (page_number - 1);
    }

    return (
      <ReactPaginate
        pageRangeDisplayed={5}
        containerClassName="pagination justify-content-center"
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        onPageChange={this.onPageChange}
        {...calculated}
        {...rest}
      />
    )
  }
}


export default IndexView;
