import React from 'react';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import isNil from 'lodash/isNil';
import {patchFormToPatch} from '../helpers/patch_form_helpers'

export const observerLink = (observation) =>
{
  if(observation.observer_type === 'Admin')
  {
    // return <div></div>
    return <Link to={`/admins/${observation.observer_id}`}><span className="badge badge-admins">{observation.observer_id}</span></Link>;
  }
  else if (observation.observer_type === 'Explorer')
  {
    return <Link to={`/explorers/${observation.observer_id}`}><span className="badge badge-explorers">{observation.observer_id}</span></Link>;
  }
  else
  {
    return <span className="badge badge-secondary">System</span>;
  }
}

export const stringToBool = (str) => {
  if(str === true || str === "true" || str === "1"){ return true  }
  if(str === false || str === "false" || str === "0"){ return false }
  return null;
};

export const generateObservationChangesJson = (changes, patchForm) =>
{
  const toSave = {}
  const data = {data: toSave}
  toSave.attributes = {}
  toSave.attributes.patch = patchFormToPatch(patchForm)
  if(isNil(changes))
    return data
  switch(changes.get("status_call"))
  {
    case "answered":
      toSave.attributes.answered = true
      toSave.attributes.connected = true
      toSave.attributes.voicemail = false
      toSave.attributes.wrong_phone= false
      break;
    case "unanswered":
      toSave.attributes.answered = false
      toSave.attributes.connected = true
      toSave.attributes.voicemail = false
      toSave.attributes.wrong_phone= false
      break;
    case "voicemail":
      toSave.attributes.answered = false
      toSave.attributes.connected = true
      toSave.attributes.voicemail = true
      toSave.attributes.wrong_phone= false
      break;
    case "wrong_phone":
      toSave.attributes.answered = false
      toSave.attributes.connected = false
      toSave.attributes.voicemail = false
      toSave.attributes.wrong_phone= true
    break;
    default:
      break;
  }

  if(changes.get("error_wrong_intent"))
    toSave.attributes.error_wrong_intent = changes.get("error_wrong_intent")
  if(changes.get("error_speech_to_text"))
    toSave.attributes.error_speech_to_text = changes.get("error_speech_to_text")
  if(changes.get("error_telephony_issue"))
    toSave.attributes.error_telephony_issue = changes.get("error_telephony_issue")

  return data
}


export const savePlaceManualObservation = (placeId, patchForm, saveFunc) =>
{
  const json = generateObservationChangesJson(Immutable.fromJS({}), patchForm)
  if(json.data.attributes.patch.length === 0)
    return
    saveFunc(placeId, json).then(json =>
    {
      return json
    })
}

export const saveObservationChanges = (observationId, changes, patchForm, saveFunc) =>
{
  const json = generateObservationChangesJson(changes, patchForm)
  const wrongIntent = json.data.attributes.error_wrong_intent// getIn(["attributes", "error_wrong_intent"])
  const errorSpeechToText = json.data.attributes.error_speech_to_text
  const errorTelephonyIssue=json.data.attributes.error_telephony_issue

  if(changes.get("status_call") === undefined && wrongIntent === undefined && errorSpeechToText === undefined && errorTelephonyIssue === undefined)
  {
    if(json.data.attributes.response.length === 0)
      return
  }
  saveFunc(observationId, json).then(json =>
  {
    return json
  }
  )
}
