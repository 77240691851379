import Store from '../../admin/reducers/store';

export const includedToActions = (included,actionType='merge') => {
  const results = [];

  const grouped = {}
  included.forEach(i => {
    grouped[i.type] ? grouped[i.type].push(i) : (grouped[i.type] = [i]);
  });
  for(let resource in grouped){
    results.push({type: `${resource}.${actionType}`, data: grouped[resource]});
  }
  return results;
};

/*
export const handleResponse = (json, type) =>
{
  if(json.included)
  {
    includedToActions(json.included).forEach(action => {
      Store.dispatch(action);
    });
  }
  Store.dispatch({type: type, data: [json.data] });
}
*/
export const dispatchResourcesTo = (store) => {
  return (json) => {
    generateResourceResponse(json).forEach(store.dispatch);
  }
};

const noTransform = (resource) => resource;

export const generateResourceResponse = (json,resourceTransform=noTransform,actionType='merge') => {
  const grouped = {};

  if(json && json.data){
    let data = json.data;
    if(json.data.attributes){ data = [data]; }

    data.forEach(resource => {
      const r = resourceTransform(resource);
      const type = r.type;
      grouped[type] = grouped[type] || []
      grouped[type].push(r);
    });
  }

  if(json && json.included){
    json.included.forEach(resource => {
      const r = resourceTransform(resource);
      const type = r.type;
      grouped[type] = grouped[type] || []
      grouped[type].push(r);
    });
  }

  const actions = Object.entries(grouped).map(([type,collection]) => {
    return { type: `${type}.merge`, data: collection };
  });

  if( json && json.meta ){
    actions.push({
      type: 'page.merge',
      data: json.meta,
    });
  }

  return actions;
};

export const handleResponse = (type,actionType) =>
{
  return (json) =>
  {
    if(json && json.meta)
    {
      const {total,...meta} = json.meta;
      if(total)
      {
        // fucking total is a pain in the ass, total should relate only to one type. could just leave it as total but risk that it gets mistaken
        meta.totals = {[type]: total};
      }
      Store.dispatch({type: 'page.merge', data: meta});
    }

    if(json.included)
    {
      includedToActions(json.included).forEach(action => {
        Store.dispatch(action);
      });
    }
    if(json && json.data)
    {
      const data = Array.isArray(json.data) ? json.data : [json.data]
      Store.dispatch({type: `${type}.${actionType}`, data: data });
    }
    return json;
  }
}


export const transformPrevalidations = (data_key) => {
  return (resource) => {
    if(resource.type === 'validation'){
      resource.type = 'prevalidation';
      resource.attributes.data_key = data_key
      return resource;
    } else {
      return resource;
    }
  }
};

export const errorResponseToFormErrors = (response) => {
  return response.errors.reduce((o,e) => {
    const attr = (e.meta && e.meta.attribute) || e.code.match(/\w+\.\w+\.(\w+)/)[1];
    o[attr] = e.code;
    return o;
  },{});
};
