import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Switch,Route,Link } from 'react-router-dom';


import ObservationsActions from '../actions/observations_actions';

import ObservationsDataPanel from './observations_data_panel';
import ObservationsBotCall from './observations_bot_call'
import ObservationsPatchPanel from './observations_patch_panel'
import ObservationsDependentsPanel from './observations_dependents_panel';
import ObservationsImagesPanel from './observations_images_panel';
import ObservationsMapPanel from './observations_map_panel';
import ObservationsPayablesPanel from './observations_payables_panel';
import ObservationsVerifyPanel from './observations_verify_panel';
import { observerLink } from '../helpers/observation_helpers'
import LiveDate from './live_date';



class ObservationsShow extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      tab: 'details',
    };
  }

  componentDidMount()
  {
    ObservationsActions.show(this.props.match.params.id).then(json =>
      {
        this.setState({loaded:true});
      }
    )
  }

  render()
  {
    if(this.state.loaded)
    {
      const { observation } = this.props;
      const subpathMatch = this.props.location.pathname.match(/observations\/\d+\/(\w+)/);
      const subpath = subpathMatch ? subpathMatch[1] : '';

      let placeLink;
      if(observation.place_id){
        placeLink = (
          <span className="mr-2">Place <Link to={`/places/${observation.place_id}/edit`}><span className="badge badge-places">{observation.place_id}</span></Link></span>
        )
      }

      let parentLink;
      if(observation.parent_id){
        parentLink = (
          <span className="mr-2">Parent <Link to={`/observations/${observation.parent_id}/edit`}><span className="badge badge-observations">{observation.parent_id}</span></Link></span>
        )
      }

      let botCallReviewLink;
      let botCallReviewRoute;
      if( ['BotCallObservation','BotCallPingObservation'].includes(this.props.observation.type) ){
        botCallReviewLink = (
          <li className="nav-item">
            <Link className={cn('nav-link',{active: (subpath === 'review')})} to={`/observations/${observation.id}/review`}>Review</Link>
          </li>
        );
        botCallReviewRoute = (
          <Route exact path="/observations/:id/review" component={ObservationsBotCall} />
        );
      }

      return (
        <div id="observationsShow" className="mb-5">
          <div className="row mt-3">
            <div className="col">
              <h2>
                <span className="badge badge-observations">{observation.id}</span>
                <span className="badge badge-dark ml-2">{observation.type}</span>
              </h2>
              <div className="clearfix mb-2"></div>
              { placeLink }
              { parentLink }
              <div className="clearfix mb-2"></div>
              <p>Observer id: { observerLink(observation)}</p>
              <p>Created at <LiveDate date={observation.created_at} />, updated at <LiveDate date={observation.updated_at} /></p>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <div className="card">
                <div className="card-header">
                  <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === '' || subpath === 'data')})} to={`/observations/${observation.id}/data`}>Data</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'patch')})} to={`/observations/${observation.id}/patch`}>Patch</Link>
                    </li>
                    {botCallReviewLink}
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'dependents')})} to={`/observations/${observation.id}/dependents`}>Dependents</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'images')})} to={`/observations/${observation.id}/images`}>Images</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'map')})} to={`/observations/${observation.id}/map`}>Map</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'payables')})} to={`/observations/${observation.id}/payables`}>Payables</Link>
                    </li>
                    <li className="nav-item">
                      <Link className={cn('nav-link',{active: (subpath === 'verify')})} to={`/observations/${observation.id}/verify`}>Verify</Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <Switch>
                    <Route exact path="/observations/:id" component={ObservationsDataPanel} />
                    <Route exact path="/observations/:id/data" component={ObservationsDataPanel} />
                    <Route exact path="/observations/:id/patch" component={ObservationsPatchPanel} />
                    { botCallReviewRoute }
                    <Route exact path="/observations/:id/dependents" component={ObservationsDependentsPanel} />
                    <Route exact path="/observations/:id/images" component={ObservationsImagesPanel} />
                    <Route exact path="/observations/:id/map" component={ObservationsMapPanel} />
                    <Route exact path="/observations/:id/payables" component={ObservationsPayablesPanel} />
                    <Route exact path="/observations/:id/verify" component={ObservationsVerifyPanel} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return 'loading...'
    }

  }

  switchTab = (e) => {
    e.preventDefault();
    this.setState({tab: e.target.getAttribute('data-tab')})
  }

}

const mapStateToProps = (state,props) => {
  let place;

  const observation = state.getIn(['observations',props.match.params.id]);
  if(observation)
  {
    if(observation.observable_id)
    {
      place = state.getIn(['places',observation.observable_id.toString()]);
    }
  }

  return{
    observation,
    place
  }
}

export default connect(mapStateToProps)(ObservationsShow);
