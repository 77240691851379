import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import schema from '../../shared/schemas/data_schema'

import cn from 'classnames';
import defaultTo from 'lodash/defaultTo';
import difference from 'lodash/difference';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';
import ConfidenceScale from 'react-confidence-scale';
import Autosuggest from 'react-autosuggest';
import { validateRowPatch,validateRowData, dataErrorToText, patchErrorToText} from '../helpers/patch_form_helpers';
//import {
//  BusinessCategory,
//} from 'shared.js';
import BusinessCategoryActions from '../actions/business_category_actions';
const properties = schema.get("properties").keySeq().toJSON().sort();

class PatchFormRow extends React.PureComponent {

  constructor(props)
  {
    super(props);
    this.state = {
      typeAssertion:"name",
      value: '',
    suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
     this.setState({
       value: newValue
     });
   };

   // Autosuggest will call this function every time you need to update suggestions.
   // You already implemented this logic above, so just use it.
   onSuggestionsFetchRequested = ({ value }) => {
     this.setState({
       suggestions: this.getSuggestions(value)
     });
   };

   // Autosuggest will call this function every time you need to clear suggestions.
   onSuggestionsClearRequested = () => {
     this.setState({
       suggestions: []
     });
   };


  onClickVerify = () =>
  {
    const v = (this.props.row.get('assert') === true) ? undefined : true;
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {assert: v})
    this.props.validate(this.props.row.merge({assert: v}))
  }

  onClickUnverify = () =>
  {
    const v = (this.props.row.get('assert') === false) ? undefined : false;
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {assert: v})
    this.props.validate(this.props.row.merge({assert: v}))
  }

  confidenceChange = (value) =>
  {
    const c = (this.props.row.get("confidence"))
    if( c === value)
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), {confidence:undefined})
      this.props.validate(this.props.row.merge({confidence: undefined}))
    }
    else
    {
      this.props.updateRow(this.props.formKey, this.props.row.get('id'), {confidence:value})
      this.props.validate(this.props.row.merge({confidence: value}))
    }

  }

  selectSpinner = (event) =>
  {
    let old_value = this.props.data.get(event.target.value)
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {attribute:event.target.value, old_value:(old_value || "")})
    this.props.validate(this.props.row.merge({attribute:event.target.value, old_value:(old_value || "")}))
  }

  onChangeAS = (event, { newValue }) => {
    //debugger
     this.setState({
       value: newValue
     });
     if(!(event.nativeEvent instanceof MouseEvent))
     {
       this.props.updateRow(this.props.formKey, this.props.row.get('id'), {new_value:undefined})
       this.props.validate(this.props.row.merge({new_value:undefined}))
       //this.props.updateErrors(this.props.formKey, this.props.row.get('id'), "You must select a category")
     }

   };

  onChange = e =>
  {
    if(e === undefined)
      return
    let value = e.target.value
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {new_value:value})
    this.props.validate(this.props.row.merge({new_value:value}))
  }

  onBlur = e =>
  {
    if(e === undefined)
      return

    const patchErrors = validateRowPatch(this.props.row.merge({new_value: e.target.value}));
    if(patchErrors && patchErrors[0])
    {
      this.props.updateErrors(this.props.formKey, this.props.row.get('id'), patchErrorToText(patchErrors[0]))
    }

    const dataErrors = validateRowData(this.props.row.merge({new_value: e.target.value}));
    if(dataErrors && dataErrors[0])
    {
      this.props.updateErrors(this.props.formKey, this.props.row.get('id'), dataErrorToText(dataErrors[0]))
    }
    this.props.validate(this.props.row.merge({new_value: e.target.value}))
  }

  booleanOnChange = e =>
  {
    let bool;
    const str = e.target.value
    if(str === true || str === "true" || str === "1"){ bool =  true  }
    if(str === false || str === "false" || str === "0"){ bool = false }
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {new_value: bool})
    this.props.validate(this.props.row.merge({new_value: bool}))
  }

  filterBy = (val, categories) =>
  {
    var result = Object.keys(categories).reduce(function(r, e)
    {
      if (e.toLowerCase().indexOf(val) == 0)
      {
        r[e] = categories[e].text;
      }
      else
      {
        Object.keys(categories[e]).forEach(function(k) {
          if (k.toLowerCase().indexOf(val) == 0) {
            var object = {}
            object[k] = categories[e][k].text;
            r[e] = object;
          }
        })
      }
      return r;
    }, {})

    var results = [];
    for(var i in result)
      results.push([i, result [i]]);
    return results;
  }


  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value =>
  {
    if(isNil(value) || value === "")
      return []
    const {categories } = this.props;
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength <= 2 ? [] : this.filterBy(value, categories)
  };

  getSuggestionValue = suggestion =>
  {
    this.props.updateRow(this.props.formKey, this.props.row.get('id'), {new_value:suggestion[0]})
    this.props.validate(this.props.row.merge({new_value: suggestion[0]}))
    return suggestion[1];
  }
  renderSuggestion = suggestion =>
  {
    return <span style={{color: 'black'}}>
      {suggestion[1]}
    </span>
  }

  render()
  {

    const { row,fieldAttrs,form_errors, categories } = this.props;

    const { value, suggestions } = this.state;

    let colorCheck, colorTimes

    const titleVerified = this.props.row.get("assert")

    if(isNil(categories))
      return null

    if(titleVerified === undefined)
    {
      colorCheck = "grey"
      colorTimes = "grey"
    }
    else
    {
      colorCheck = titleVerified?"green":"grey";
      colorTimes = titleVerified?"grey":"red";
    }
    // const propertiesSchema = schema.get("properties")
    // let assertions = Array.from(propertiesSchema.keys())

    const fields = difference(properties,fieldAttrs.toJSON());
    const indexOfPC = fields.indexOf('permanently_closed')
    if(indexOfPC > -1)
      fields.splice(indexOfPC, 1)
    const indexOfTC = fields.indexOf('temporarily_closed')
    if(indexOfTC > -1)
      fields.splice(indexOfTC, 1)

    if(row.get('attribute')){ fields.unshift(row.get('attribute')) }
    const dditems = fields.map(element =>  <option key={element} value={element}>{element}</option>)

    let inputType

    if(isNil(this.props.row.get('attribute')) === false)
    {
      const type = schema.getIn(["properties",this.props.row.get('attribute'),'type'])
      switch(type)
      {
        case "string":
          inputType = "text"
        break
        case "number":
          inputType = "number"
        break
        case "boolean":
          inputType = "boolean"
          break
        default:
          inputType = "text"
        break
      }
    }

    const formValue = row.has('new_value') ? row.get('new_value') : row.get('old_value');

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Type a category',
      value,
      onChange: this.onChangeAS
    };

    let input

    if(inputType === "boolean")
      input =  <select className="custom-select" name="selectBoool" onChange= {this.booleanOnChange} value = {formValue}>
                  <option value=''></option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
               </select>
    else if(isNil(inputType) === false && this.props.row.get('attribute') !== "business_category")
      input = <input className="form-control" type={inputType} name="fname" onBlur = {this.onBlur} onChange = {this.onChange} value={formValue}/>
    else if (isNil(inputType) === false)
    {
      input = <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
   />
    }

    let trashButton
    let selectAttribute

    if( isNil(this.props.row.get('pinned')))
    {
      trashButton = <FontAwesomeIcon icon={faTrash} className="mr-3" size="lg" color={"white"} onClick ={this.removeRow}/>
      selectAttribute = <select className="custom-select mr-sm-2" name="bot_env" value= {this.props.row.get('attribute')} onChange = {this.selectSpinner }>
        <option value=''></option>
        {dditems}
      </select>
    }
    else
      selectAttribute = this.props.row.get('attribute')

    return(
    <div className="has-danger">
      <div className="row border-bottom p-2">
        <div className="col-2 text-white d-flex align-items-center">
          {trashButton}
          <small className="text-monospace">{selectAttribute}</small>
        </div>
        <div className="col-6 text-white d-flex align-items-left">
          {input}
        </div>
        <div className="col-1 d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faCheck} className="mr-3" size="lg" color={colorCheck} onClick ={this.onClickVerify}/>
          <FontAwesomeIcon icon={faTimes} className="" size="lg" color={colorTimes} onClick ={this.onClickUnverify}/>
        </div>
        <div className="col-3 d-flex align-items-center">
          <ConfidenceScale value={this.props.row.get('confidence')} onChange={this.confidenceChange}/>
        </div>
        <div className="col-1"/>
        <div className="col d-flex align-items-center text-danger invalid-feedback">
          {form_errors}
        </div>
      </div>
    </div>
    )
  }

  removeRow = () => {
    this.props.removeRow(this.props.formKey,this.props.row.get('id'))
  }
}

const mapDispatchToProps = {
  updateRow: (formKey,id,changes) => ({ type: 'forms.merge', data: { [formKey]: { [id]: changes } } }),
  removeRow: (formKey,id) => ({ type: 'forms.delete_in', data: [[formKey,id]]}),
  updateErrors:(formKey, id, errors) => ({type: 'form_errors.merge', data: { [formKey]: { [id]: errors } } }),
}

const mapStateToProps = (initialState,initialProps) => {
  const formKey = initialProps.formKey;
  const id = initialProps.row.get('id')
  const categories = initialState.getIn(["business_categories"])

  return ({
      form_errors: initialState.getIn(['form_errors',formKey, id,"error"]),
      categories: categories,
    });

};

export default connect(mapStateToProps ,mapDispatchToProps)(PatchFormRow);
