import isNil from 'lodash/isNil';
import Ajv from 'ajv';
import {raw as dataSchema} from '../../shared/schemas/data_schema'
import {raw as patchSchema} from '../../shared/schemas/patch_schema';
const ajv = new Ajv();


// formData is Immutable.Map
export const patchFormToPatch = (formData) => {
  return formData.reduce((patch,formRow) => {
    return patch.concat(_patchFromFormRow(formRow))
  },[]);
};

export const dataPatchFormToPatch = (formData) =>
{
  let toReturn = {}
  formData.forEach(element => {_dataPatchFromRow(toReturn, element)})
  return toReturn
}


export const validateRowPatch = (formRow) => {
  const patchSchemaValidator = ajv.compile(patchSchema);
  patchSchemaValidator(_patchFromFormRow(formRow))
  return patchSchemaValidator.errors
};

export const validateRowData = (formRow) => {
  const value = formRow.has('new_value') ? formRow.get('new_value') : formRow.get('old_value');
  const dataSchemaValidator = ajv.compile(dataSchema);
  dataSchemaValidator({[formRow.get('attribute')]: value});
  return dataSchemaValidator.errors;
};

export const patchErrorToText = (ajvError) =>
{
  switch(ajvError.keyword)
  {
    case 'required':
    case 'type':
      return ajvError.message
    case 'pattern':
    case 'format':
      return ajvError.dataPath + " doesn't have the right format"
   case 'const':
   case 'enum':
   case 'minLength':
   case 'maxLength':
    return ajvError.message
   default:
     console.log(ajvError)
     return null;
  }
}

export const dataErrorToText = (ajvError) =>
{
  switch(ajvError.keyword)
  {
    case 'required':
    case 'type':
      const missingProperty = ajvError.params.missingProperty
      if(missingProperty && missingProperty === 'c')
        return "Missing confidence value"
      else if(missingProperty && missingProperty === 'o')
        return "Missing assertion, true or false"
      else if (missingProperty && missingProperty === 'v')
        return "Missing value"
      else
        return ajvError.message
    case 'pattern':
    case 'format':
      return ajvError.dataPath +" doesn't have the right format"
    case 'const':
    case 'enum':
    case 'minLength':
    case 'maxLength':
      return ajvError.message
    default:
      console.log(ajvError)
      return null;
  }
}


const _dataPatchFromRow = (object, formRow) =>
{
    const p = formRow.get('attribute')
    const v = formRow.get('new_value')
    if(!isNil(v) && !(v===''))
      object[p] = v
}

const _patchFromFormRow = (formRow) => {
  const patch = []
  let   o = formRow.get('assert');
  let   p = `/${formRow.get('attribute')}`;
  const c = formRow.get('confidence');
  const v = formRow.get('new_value')

  if(isNil(o) && isNil(p) && isNil(c) && isNil(v))
  {
    return patch;
  }
  if(p === '/business_status')
  {
    if(!isNil(c))
    {
      if(v === 'tclosed')
      {
        patch.push({o:true, p:'/temporarily_closed', c, v: true})
        patch.push({o:true, p:'/permanently_closed', c, v: false})      
        return patch
      }
      else if(v === 'pclosed')
      {
        patch.push({o:true, p:'/temporarily_closed', c, v: false})
        patch.push({o:true, p:'/permanently_closed', c, v: true})
        return patch
      }
      else if(v === 'open')
      {
        patch.push({o:true, p:'/temporarily_closed', c, v: false})
        patch.push({o:true, p:'/permanently_closed', c, v: false})
        return patch
      }
    }
  }

  if( !isNil(o) )
  {
    if( !isNil(v) && !(v === '') )
    {
      patch.push({o, v: v, p, c});
    }
    else if( !isNil(formRow.get('old_value')) &&!(formRow.get('old_value') === ''))
    {
      patch.push({o, v: formRow.get('old_value'), p, c});
    }
    else if(!(v === ''))
    {
      patch.push({o, v: undefined, p, c});
    }

    if( o === true && !isNil(v) && !isNil(formRow.get('old_value')) ){
      patch.push({o: false, v: formRow.get('old_value'), p, c});
    }
  }
  else if(!isNil(v) && !(v === ''))
  {
    patch.push({o, v: v, p, c})
  }
  else if(!isNil(c))
  {
    patch.push({o, v: formRow.get('old_value'), p, c})
  }

  return patch;
};
