import { createStore } from 'redux';
import { combineReducers } from 'redux-immutable';

import current_admin from './admin_reducer';

import AdminExplorer from '../models/admin_explorer';
import Place from '../../shared/models/place';
import AdminVisit from '../models/admin_visit';
import AdminValidation from '../models/admin_validation';
import AdminLead from '../models/admin_lead';

import ExplorerProject from '../../shared/models/explorer_project';
import Payment from '../../shared/models/payment';
import Payable from '../../shared/models/payable';
import PlaceValidation from '../../shared/models/place_validation';
import PricingItem from '../../shared/models/pricing_item';
import PricingStructure from '../../shared/models/pricing_structure';
import Image from '../../shared/models/image';
import Project from '../../shared/models/project';
import Admin from '../models/admin'
import Region from '../../shared/models/region';
import Observation from '../../shared/models/observation';
import ProjectVisit from '../../shared/models/project_visit';
import Task from '../../shared/models/task';

import {
  createResourceReducer,
  createFormReducer,
  createFormChangesReducer,
  createFormErrorsReducer,
  createBasicReducer,
  createVanillaReducer
} from '../../shared/helpers/reducer_helpers';

const formsDefaults = {
  'places/edit': { },
  'visits/edit': { },
  'place_validations/edit': { },
  'explorers/edit': { },
  'leads/edit': { },
  'projects/edit': { },
};

const reducers = combineReducers({
  admins:              createResourceReducer(Admin, 'admin', 'admins'),
  explorers:           createResourceReducer(AdminExplorer,'explorer','explorers'),
  explorers_payments:  createResourceReducer(AdminExplorer,'explorers_payment','explorers_payments'),
  explorers_quotas:    createResourceReducer(AdminExplorer,'explorer_quota','explorers_quotas'),
  explorer_projects:   createResourceReducer(ExplorerProject,'explorer_project','explorer_projects'),
  project_visits:      createResourceReducer(ProjectVisit,'project_visit','project_visits'),
  projects:            createResourceReducer(Project,'project','projects'),
  regions:             createResourceReducer(Region,'region','regions'),
  leads:               createResourceReducer(AdminLead,'lead','leads'),
  images:              createResourceReducer(Image,'images','image'),
  places:              createResourceReducer(Place,'place','places'),
  payments:            createResourceReducer(Payment,'payment','payments'),
  payables:            createResourceReducer(Payable,'payable','payables'),
  place_validations:   createResourceReducer(PlaceValidation,'place_validation','place_validations'),
  pricing_items:       createResourceReducer(PricingItem,'pricing_item','pricing_items'),
  visits:              createResourceReducer(AdminVisit,'visit','visits'),
  observations:        createResourceReducer(Observation,'observation','observations'),
  pricing_structures:  createResourceReducer(PricingStructure,'pricing_structure','pricing_structures'),
  validations:         createResourceReducer(AdminValidation,'validation','validations'),
  tasks:               createResourceReducer(Task,'tasks','task'),
  page:                createBasicReducer('page'),
  forms:               createFormReducer(formsDefaults),
  form_changes:        createFormChangesReducer(formsDefaults),
  form_errors:         createFormErrorsReducer(formsDefaults),
  form_async_errors:   createFormErrorsReducer(formsDefaults,'form_async_errors'),
  form_async_warnings: createFormErrorsReducer(formsDefaults,'form_async_warnings'),
  business_categories: createVanillaReducer('business_categories',{}),
  prevalidations:      createResourceReducer(AdminValidation,'prevalidaiton','prevalidations'),
  current_admin:       current_admin,
});

export default createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
